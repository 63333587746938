var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "landingpageForm",
      class: { "landingpageForm--inline": _vm.inline },
    },
    [
      !_vm.isSent
        ? _c(
            "form",
            { staticClass: "landingpageForm__form" },
            [
              _c("ul", { staticClass: "landingpageForm__steps" }, [
                _c("li", { class: { active: _vm.step === 1 } }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showStep(1)
                        },
                      },
                    },
                    [_vm._v("1")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { class: { active: _vm.step === 2 } }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showStep(2)
                        },
                      },
                    },
                    [_vm._v("2")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { class: { active: _vm.step === 3 } }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showStep(3)
                        },
                      },
                    },
                    [_vm._v("3")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "landingpageForm__wrap" },
                _vm._l(_vm.steps, function (i) {
                  return _c("landingpage-form-step" + i, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step === i,
                        expression: "step === i",
                      },
                    ],
                    key: i,
                    ref: "steps",
                    refInFor: true,
                    tag: "component",
                    attrs: {
                      step: i,
                      data: _vm.data,
                      context: _vm.context,
                      isExpanded: _vm.expandedComponent !== null,
                    },
                    on: { expand: _vm.onExpand, goTo: _vm.onGoTo },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "open" } }, [
                _vm.expandedComponent !== null
                  ? _c(
                      "div",
                      { staticClass: "landingpageForm__extended" },
                      [
                        _c(_vm.expandedComponent, {
                          ref: "expandedContent",
                          tag: "component",
                          attrs: { data: _vm.data, context: _vm.context },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSent
        ? _c("div", { staticClass: "landingpageForm__success" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("requestEmployees.success")) +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }