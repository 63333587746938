var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "column-small" },
      [
        _c("label", { attrs: { for: "contact-department" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("contact.form.company.department")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "contact-department",
            error: _vm.departmentErrorMessage,
            placeholder: _vm.$t("contact.form.company.department"),
            required: "required",
            type: "text",
          },
          model: {
            value: _vm.data.department,
            callback: function ($$v) {
              _vm.$set(_vm.data, "department", $$v)
            },
            expression: "data.department",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column-small" },
      [
        _c("label", { attrs: { for: "request-employees-phone" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.phone.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-phone",
            placeholder: _vm.$t("requestEmployees.form.phone.placeholder"),
            type: "text",
          },
          model: {
            value: _vm.data.phone,
            callback: function ($$v) {
              _vm.$set(_vm.data, "phone", $$v)
            },
            expression: "data.phone",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column-full" },
      [
        _c("label", { attrs: { for: "request-employees-message" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.message.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("textarea-field", {
          attrs: {
            id: "request-employees-message",
            placeholder: _vm.$t("requestEmployees.form.message.placeholder"),
          },
          model: {
            value: _vm.data.message,
            callback: function ($$v) {
              _vm.$set(_vm.data, "message", $$v)
            },
            expression: "data.message",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column-full" },
      [
        _c("checkbox", {
          attrs: {
            error: _vm.confirmGdprErrorMessage,
            items: _vm.confirmGdprOptions,
          },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function (props) {
                return [
                  props.item.confirmGdpr
                    ? _c(
                        "span",
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t(
                                  "requestEmployees.form.confirmGdpr.options.yes.1"
                                )
                              ) +
                              "\n                    "
                          ),
                          _c(
                            "link-wrap",
                            { attrs: { link: _vm.privacyPolicyPage } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "requestEmployees.form.confirmGdpr.options.yes.2"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t(
                                  "requestEmployees.form.confirmGdpr.options.yes.3"
                                )
                              ) +
                              "\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.gdprModelBridge,
            callback: function ($$v) {
              _vm.gdprModelBridge = $$v
            },
            expression: "gdprModelBridge",
          },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCompanyPage,
                expression: "!isCompanyPage",
              },
            ],
            staticClass: "small",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("requestEmployees.form.generalContact.text")) +
                "\n            "
            ),
            _c("mail-wrap", { attrs: { address: "kontakt@persona.de" } }, [
              _vm._v("\n                kontakt@persona.de.\n            "),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons buttons--sm" },
          [
            _c(
              "btn",
              {
                attrs: { "is-disabled": !_vm.parentComponent.isFormValid },
                nativeOn: {
                  click: function ($event) {
                    return _vm.parentComponent.onButtonClick.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("requestEmployees.form.step3.submit")) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }