


















































import {forEach, isNumeric} from '@labor-digital/helferlein';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {Branch} from '../../../../Interface/Branch';
import FormTypeahead from '../../../Form/FormTypeahead/FormTypeahead.vue';
import InputField from '../../../Form/InputField/InputField.vue';
import MultiSelectBox from '../../../Form/MultiSelectBox/MultiSelectBox';
import SelectBox from '../../../Form/SelectBox/SelectBox';
import StepMixin from '../../../Layout/MainMenu/Module/RequestEmployeesModule/Steps/StepMixin';
import Btn from '../../../Misc/Btn/Btn';
import Dropdown from '../../../Misc/Dropdown/Dropdown.vue';

export default {
    name: 'LandingpageFormStep1',
    components: {FormTypeahead, Dropdown, MultiSelectBox, SelectBox, InputField, Btn},
    mixins: [StepMixin],
    data: function () {
        return {
            customer: this.context.data.get('customer', null),
            waitForBranchApiRequest: false,
            isZipValidInternal: false
        };
    },
    computed: {
        dropdownItems(): Array<{ label: string, value: string }>
        {
            let items = [];
            const data = this.context.data.get('dropdown', []);
            forEach(data, item => {
                items.push({value: item, label: item});
            });

            return items;
        },
        isZipValid(): boolean
        {
            return isNumeric(this.data.zip);
        },
        zipErrorMessage(): string | null
        {
            if (this.waitForBranchApiRequest) {
                return null;
            }
            return this.isZipValid || this.data.zip === '' ? null : this.$t('requestEmployees.form.zip.error');
        }
    },
    methods: {
        validate(): boolean
        {
            return this.isZipValid;
        }
    },
    watch: {
        'data.zip': function (n) {
            this.isZipValidInternal = false;

            if (isEmpty(n) || !isNumeric(n)) {
                return;
            }

            debouncePromise('requestEmployeeZip', () => {
                this.waitForBranchApiRequest = true;
                return this.context.resourceApi.getAdditional('branch', 'byZip/' + n);
            }, 600, true)
                .then(response => {
                    this.data.branch = response.getAll() as Branch;
                    this.isZipValidInternal = true;
                })
                .catch(() => {
                    this.isZipValidInternal = false;
                })
                .then(() => {
                    this.waitForBranchApiRequest = false;
                });
        }
    }
};
