






















































import {validateEmail} from '@labor-digital/helferlein';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import InputField from '../../../Form/InputField/InputField.vue';
import StepMixin from '../../../Layout/MainMenu/Module/RequestEmployeesModule/Steps/StepMixin';
import Btn from '../../../Misc/Btn/Btn';
import LandingpageFormStep31 from './LandingpageFormStep31.vue';

export default {
    name: 'LandingpageFormStep3',
    components: {InputField, Btn},
    mixins: [StepMixin],
    computed: {
        isEmailValid(): boolean
        {
            return !isEmpty(this.data.email) && validateEmail(this.data.email);
        },
        emailErrorMessage(): string | null
        {
            return this.isEmailValid || this.data.email === '' ? null : this.$t('requestEmployees.form.email.error');
        }
    },
    methods: {
        onShow()
        {
            const that = this;
            this.$emit('expand', {
                extends: LandingpageFormStep31,
                computed: {
                    parentComponent: () => this,
                    errors: () => {
                        return that.errors;
                    }
                }
            });
        },
        validate(): boolean
        {
            // Test if expanded content is valid
            const isChildValid = this.$parent.$refs.expandedContent ? this.$parent.$refs.expandedContent.validate() : true;

            return this.isEmailValid && isChildValid;
        }
    }
};
