



























































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {isFunction} from '@labor-digital/helferlein/lib/Types/isFunction';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {FbPixelService} from '../../../Service/FbPixelService';
import {GoogleTagManagerService} from '../../../Service/GoogleTagManagerService';
import {MailService} from '../../../Service/MailService';
import Checkbox from '../../Form/Checkbox/Checkbox.vue';
import Chips from '../../Form/Chips/Chips.vue';
import InputField from '../../Form/InputField/InputField.vue';
import MultiSelectBox from '../../Form/MultiSelectBox/MultiSelectBox.vue';
import SelectBox from '../../Form/SelectBox/SelectBox.vue';
import Btn from '../../Misc/Btn/Btn.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import LandingpageFormStep1 from './Steps/LandingpageFormStep1.vue';
import LandingpageFormStep2 from './Steps/LandingpageFormStep2.vue';
import LandingpageFormStep3 from './Steps/LandingpageFormStep3.vue';


export default {
    name: 'LandingpageForm',
    components: {
        LandingpageFormStep1, LandingpageFormStep2, LandingpageFormStep3,
        MultiSelectBox, InputField, Checkbox, LinkWrap, SelectBox, Icon, Btn, Chips
    },
    props: {
        context: null as PageContext,
        inline: {
            type: Boolean,
            default: false
        },
        branch: {
            default: null
        }
    },
    data()
    {
        return {
            maxStep: 1,
            steps: 3,
            step: 1,
            isSent: false,
            email: this.context.data.get('email', ''),
            token: this.context.data.get('token', ''),
            data: {
                customer: this.context.data.get('customer', ''),
                campaign: this.context.data.get('campaign', ''),

                // Step 1
                occupation: null,
                zip: this.branch === null ? '' : (this.branch.zip + ''),
                branch: this.branch ?? null,

                // Step 2
                amount: null,
                startDate: null,
                endDate: null,

                // Step 3
                name: {
                    first: '',
                    last: ''
                },
                email: '',
                phone: '',
                department: '',
                message: '',
                company: '',
                confirmGdpr: false

            },
            expandedComponent: null
        };
    },
    methods: {
        onExpand(targetComponent)
        {
            this.expandedComponent = targetComponent;
        },
        calcValidMaxStep(): number
        {
            let maxStep = this.maxStep;
            let maxValidStep = 1;

            forEach(this.$refs.steps, (step: PlainObject, i: number) => {
                i += 1;
                if (i > maxStep) {
                    return false;
                }
                if (step.isFormValid) {
                    maxValidStep = i + 1;
                }
            });

            return this.maxStep = maxValidStep;
        },
        processStepCallbacks()
        {
            const comp = this.$refs.steps[this.step - 1];
            if (isFunction(comp.onShow)) {
                comp.onShow();
            }
        },
        showStep: function (step: number) {
            this.expandedComponent = null;
            if (this.calcValidMaxStep() >= step) {
                this.step = step;
            }
            this.processStepCallbacks();
        },
        onGoTo(step: number)
        {
            const maxStep = this.calcValidMaxStep();
            if (maxStep < step) {
                return;
            }

            this.expandedComponent = null;
            if (step === 4) {
                this.send();
                return;
            }

            this.maxStep = step;
            this.step = step;
            this.processStepCallbacks();
        },
        send()
        {
            MailService.send(this.data.branch.email, this.data.branch.emailToken, {
                ...this.data,
                context: 'landingPageForm'
            }).then((state: boolean) => {
                if (state) {
                    this.isSent = true;
                    FbPixelService.track('LandingpageAnfrage');
                    GoogleTagManagerService.track('landingpage_form', 'LandingpageAnfrage');
                } else {
                    // @todo show error message
                    alert('Something bad happened!');
                }
            });
        }
    },
    watch: {
        branch(n)
        {
            if (this.data.branch === null) {
                this.data.branch = n;
            }
            if (this.data.zip === '') {
                this.data.zip = n.zip;
            }
        }
    }
};
