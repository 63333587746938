






















import {VuFormTypeaheadAbstract} from '@labor-digital/vuetiful/src/index';
import InputField from '../InputField/InputField.vue';

export default {
    name: 'FormTypeahead',
    extends: VuFormTypeaheadAbstract,
    props: {
        inputComponent: {
            default: () => InputField
        },
        showSpinner: {
            type: Boolean,
            default: true
        }
    }
};
