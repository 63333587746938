var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "landingpageForm__step3" }, [
    _c(
      "div",
      { staticClass: "name-first" },
      [
        _c("label", { attrs: { for: "request-employees-name-first" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.name.first.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-name-first",
            type: "text",
            placeholder: _vm.$t("requestEmployees.form.name.first.placeholder"),
          },
          model: {
            value: _vm.data.name.first,
            callback: function ($$v) {
              _vm.$set(_vm.data.name, "first", $$v)
            },
            expression: "data.name.first",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "name-last" },
      [
        _c("label", { attrs: { for: "request-employees-name-first" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.name.last.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-name-last",
            type: "text",
            placeholder: _vm.$t("requestEmployees.form.name.last.placeholder"),
          },
          model: {
            value: _vm.data.name.last,
            callback: function ($$v) {
              _vm.$set(_vm.data.name, "last", $$v)
            },
            expression: "data.name.last",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "email" },
      [
        _c("label", { attrs: { for: "request-employees-email" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("requestEmployees.form.email.label")) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("input-field", {
          attrs: {
            id: "request-employees-email",
            type: "email",
            required: "required",
            error: _vm.emailErrorMessage,
            placeholder: _vm.$t("requestEmployees.form.email.placeholder"),
          },
          model: {
            value: _vm.data.email,
            callback: function ($$v) {
              _vm.$set(_vm.data, "email", $$v)
            },
            expression: "data.email",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "buttons buttons--lg" },
      [
        _c(
          "btn",
          {
            attrs: { "is-disabled": !_vm.isFormValid },
            nativeOn: {
              click: function ($event) {
                return _vm.onButtonClick.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("requestEmployees.form.step3.submit")) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }