
























































import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import InputField from '../../../Form/InputField/InputField.vue';
import SelectBox from '../../../Form/SelectBox/SelectBox';
import StepMixin from '../../../Layout/MainMenu/Module/RequestEmployeesModule/Steps/StepMixin';
import Btn from '../../../Misc/Btn/Btn';

export default {
    name: 'RequestEmployeesModuleStep2',
    components: {InputField, SelectBox, Btn},
    mixins: [StepMixin],
    data()
    {
        return {
            amountOptions: [
                {
                    label: '1',
                    value: '1'
                },
                {
                    label: '2-5',
                    value: '2-5'
                },
                {
                    label: '6-10',
                    value: '6-10'
                },
                {
                    label: '11+',
                    value: '11+'
                }
            ]
        };
    },
    computed: {
        isAmountValid(): boolean
        {
            return !isEmpty(this.data.amount);
        },
        amountErrorMessage(): string | null
        {
            return this.isAmountValid || this.data.amount === null ? null : this.$t('requestEmployees.form.amount.error');
        }
    },
    methods: {
        validate(): boolean
        {
            return this.isAmountValid;
        }
    }
};
