//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {RootLineElementInterface} from '@labor-digital/typo3-vue-framework/lib/Core/Interface/RootLineElementInterface';
import Checkbox from '../../../Form/Checkbox/Checkbox';
import InputField from '../../../Form/InputField/InputField';
import RadioButton from '../../../Form/RadioButton/RadioButton';
import TextareaField from '../../../Form/Textarea/TextareaField';
import Btn from '../../../Misc/Btn/Btn';
import LinkWrap from '../../../Misc/Link/LinkWrap/LinkWrap';
import MailWrap from '../../../Misc/Link/MailWrap/MailWrap';

export default {
    name: 'LandingpageFormStep31',
    components: {Btn, LinkWrap, MailWrap, Checkbox, RadioButton, InputField, TextareaField},
    props: {
        context: null as PageContext,
        data: null
    },
    data()
    {
        return {
            confirmGdprOptions: [
                {
                    label: 'privacy policy',
                    value: 'gdpr',
                    confirmGdpr: true
                }
            ]
        };
    },
    methods: {
        validate(): boolean
        {
            return this.isDepartmentValid && this.isConfirmGdprValid;
        }
    },
    computed: {
        isCompanyPage(): boolean
        {
            const context: PageContext = this.context;
            const companyPage: number = context.pidRepository.getPid('page.homeForCompanies');
            // check if company page is in rootline
            return context.rootLine.filter((element: RootLineElementInterface) => {
                return element.id === companyPage;
            }).length > 0;
        },
        isConfirmGdprValid(): boolean
        {
            return this.data.confirmGdpr === true;
        },
        isDepartmentValid(): boolean
        {
            return !isEmpty(this.data.department);
        },
        departmentErrorMessage(): string | null
        {
            if (!this.isDepartmentValid) {
                return null;
            }

            return this.isDepartmentValid || this.data.department === '' ? null : this.$t('requestEmployees.form.general.error');
        },
        confirmGdprErrorMessage(): string | null
        {
            return this.isConfirmGdprValid || this.data.confirmGdpr === false ? null : this.$t('requestEmployees.form.confirmGdpr.error');
        },
        // @todo make this more intuitive <- Single checkbox component
        gdprModelBridge: {
            set(v)
            {
                this.data.confirmGdpr = v.length > 0;
            }, get()
            {
                return this.data.confirmGdpr ? [{value: 'gdpr'}] : [];
            }
        },
        privacyPolicyPage()
        {
            return this.context.pageContext.linkRepository.get('privacyPolicy');
        }
    }
};
